$theme-colors: (
  background: #fff,
  shade-dark: #888,
  shade: #ccc,
  shade-light: #f8faf7,
  foreground: #000d88,
  foreground-on-danger: #fff,
  midground: #dadcdf,
  danger: #e24040,
  warning: #eca821,
  success: #2b9a62,
  accent-1: #3286ff,
  accent-2: #3286ff,
  accent-3: #3286ff,
  light-1: #f8faf7,
  light-2: #fbbfe3,
  light-3: #fbbfe3,
  light-4: #f8faf7,
  light-4-shaded:#e3e6e9,
  light-5: #f8faf7,
  facebook: #1778F2,
  outline: #fbbfe3,
  outline-dark: #fbbfe3,
  highlight: #fbbfe3,
  footer: #fbbfe3,
  fluo: #16e8dd
);
$theme-colorsDarkMode: (
  background: #000d88,
  foreground: #fff,
  shade-light: #2a35a1
);
$theme-font-primary: 'Dudek', sans-serif;
$theme-font-secondary: 'Dudek', sans-serif;
$theme-size-border-radius: 0;
$theme-logo-main: url('../assets/logo-main.svg');
$theme-logo-main-menu: url('../assets/logo-main-menu.svg');
$theme-logo-main-width: 938;
$theme-logo-main-height: 184.2;
$theme-header-logo-size: (7.464375rem, 11.4575rem);
$theme-footer-logo-size: (3.125rem, 5rem);
$theme-footer-sponsor-width: 150px;
$theme-footer-sponsor-height: 75px;
$theme-title-font-weight: bold;
$theme-title-text-transform: none;
$theme-icon-play: url('../assets/icon-play.svg');
$theme-calls-cta-text-color: var(--color-background);
$theme-calls-cta-background-color: var(--color-accent-1);
$theme-calls-cta-left: url('../assets/calls-cta-left.svg');
$theme-calls-cta-right: url('../assets/calls-cta-right.svg');