/** Dudek */

@font-face {
  font-family: 'Dudek';
  src: url('../assets/Dudek-Regular.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Dudek';
  src: url('../assets/Dudek-Medium.otf') format('opentype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Dudek';
  src: url('../assets/Dudek-Bold.otf') format('opentype');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}